import { useContext } from "react";
import { Context } from "../context/context";

const useTemplate = () => {
  const {
    state: {
      data: { selection, finance, partExchange, details, summary },
    },
  } = useContext(Context);

  const { title, firstName, lastName, emailAddress, mobile, phone, branchTradingName, branchAddress1, branchAddress2, branchAddress3, branchPostcode, branchTelephone, branchContactEmail, finance_expiring, dealerName } = details;
  const { preferences, models } = selection;
  const { budgets, mileages, deposit } = finance;
  const { make, model, registration, transmission, fuel, colour, currentMileage, conditions, comments, ownerships, contractStartDate, contractEndDate, monthlyPayment } = partExchange;
  const { contactMethods, contactTimes, contactDays, appointmentMethods } = summary;

  const preferencesArray = [];
  const modelsArray = [];
  const contactMethodsArray = [];
  const contactTimesArray = [];
  const contactDaysArray = [];
  const budgetsArray = [];
  const mileagesArray = [];
  const conditionsArray = [];
  const ownershipsArray = [];
    const appointmentMethodsArray = [];

  preferences.map((preference) => {
    if (preference.selected) {
      preferencesArray.push(preference.title);
    }
    return undefined;
  });

  models.map((model) => {
    if (model.selected) {
      modelsArray.push(model.title);
    }
    return undefined;
  });

  contactMethods.map((method) => {
    if (method.selected) {
      contactMethodsArray.push(method.title);
    }
    return undefined;
  });

    appointmentMethods.map((method) => {
        if (method.selected) {
            appointmentMethodsArray.push(method.title);
        }
        return undefined;
    });

  contactTimes.map((time) => {
    if (time.selected) {
      contactTimesArray.push(time.title);
    }
    return undefined;
  });

  contactDays.map((day) => {
    if (day.selected) {
      contactDaysArray.push(day.title);
    }
    return undefined;
  });

  budgets.map((budget) => {
    if (budget.selected) {
      budgetsArray.push(budget.title);
    }
    return undefined;
  });

  mileages.map((mileage) => {
    if (mileage.selected) {
      mileagesArray.push(mileage.title);
    }
    return undefined;
  });

  conditions.map((condition) => {
    if (condition.selected) {
      conditionsArray.push(condition.title);
    }
    return undefined;
  });
    ownerships.map((ownership) => {
      if (ownership.selected) {
          ownershipsArray.push(ownership.title);
      }
      return undefined;
  });



  const getTemplate = () => {
    const customerTemplate = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
    
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    
        <title> Motor Group</title>
    
        <style type="text/css" rel="stylesheet">
            body {
                margin: 0px;
                padding: 0px;
                color: #000
            }
    
            th {
                text-align: left;
            }
    
            th img {
                height: auto !important;
            }
    
            .col-2-promos p,
            .col-3-promos p {
                margin: 0px;
            }
    
            .banner p {
                margin: 0px;
            }
    
            /*Medium screens*/
    
            @media only screen and (max-width:660px) {
                table[class=container] {
                    width: 90% !important;
                }
    
                th[class=banner] img {
                    width: 100%;
                    height: auto !important;
                }
    
                th[class="col-2-promos"] {
                    width: 48% !important;
                    margin-bottom: 0px;
                    display: inline-block;
                    text-align: center !important;
                }
    
                th[class="col-2-promos"] img {
                    width: 99%;
                    height: auto !important;
                }
    
                th[class="col-2-promos"] img[class="footerImg"] {
                    width: auto !important;
                    height: auto !important;
                }
    
                th[class="col-3-promos"] {
                    width: 30% !important;
                    margin-bottom: 20px;
                    text-align: center !important;
                }
    
                th[class="col-3-promos"] img {
                    width: 99% !important;
                    height: auto !important;
                }
    
                th[class="col-4-promos"] {
                    width: 24% !important;
                    margin-bottom: 10px;
                    text-align: center !important;
                    padding: 0px
                }
    
                th[class="col-4-promos"] img {
                    width: 95% !important;
                }
    
                table[class="buttonTable"] {
                    width: 100% !important;
                    margin-bottom: 20px;
                    text-align: center !important;
                }
    
                table[class="customTable"] {
                    width: 100% !important;
                    margin-bottom: 20px;
                    text-align: center !important;
                }
    
                .mobile-logo {
                    margin-left: 32%;
                    margin-right: 32%;
                    width: 36% !important;
                    margin-top: 20px;
                }
    
                .custom-class-1 {
                    width: 85% !important;
                }
    
                .custom-class-2 {
                    width: 100% !important;
                    font-size: 11px !important;
                    text-align: left !important;
                    float: left !important;
                }
    
                .offer {
                    width: 100% !important;
                    padding: 0 0 20px 0 !important;
                }
    
                .finance-table {
                    width: 100% !important;
                    font-size: 12px !important;
                }
    
                .custom-logo-class-2 {
                    width: 30px !important;
                }
    
                .custom-logo {
                    float: left !important;
                    margin: 15px 0 0 8px !important;
                }
    
                .custom-offer {
                    width: 100% !important;
                }
    
                .kenbrownfooter {
                    width: 100% !important;
                }
    
                .social-icons-98 {
                    width: 30px !important;
                    height: 30px !important;
                    text-align: center !important;
                }
    
                .value-my-car {
                    width: 100% !important;
                }
    
                .finance-table-1 {
                    width: 100% !important;
                }
            }
    
    
            /* small screens */
            @media only screen and (max-width:620px) {
    
                table[class=container] {
                    width: 100% !important;
    
                }
    
                /* outermost container*/
    
                th[class="col-row"] {
                    width: 100% !important;
                }
    
                table[class=container] th {
                    border: none !important;
                }
    
                th[class="col-2-promos"] {
                    width: 100% !important;
                    margin-bottom: 0px;
                    display: inline-block;
                    text-align: center !important;
                }
    
                th[class="col-2-promos"] img {
                    width: 99%;
                    height: auto !important;
                }
    
                th[class="col-3-promos"] {
                    width: 100% !important;
                    margin-bottom: 20px;
                    display: inline-block;
                    text-align: center !important;
                }
    
                th[class="col-4-promos"] {
                    width: 49% !important;
                    margin-bottom: 10px;
                    display: inline-block;
                    text-align: center !important;
                }
    
                th[class="col-4-promos"] img {
                    width: 95% !important;
                    margin: 0 auto !important;
                    height: auto !important;
                }
    
                table[class="buttonTable"] {
                    width: 100% !important;
                    margin-bottom: 20px;
                    text-align: center !important;
                }
    
                th[class="button"] {
                    width: 100% !important;
                    padding: 0 20px 0 20px;
                }
    
                .footer-logo-template-1-img {
                    float: none !important;
                }
    
                .social-icon {
                    width: 25px !important;
                    padding-left: 2px !important;
                    padding-right: 2px !important;
                    margin-top: 15px !important;
                    margin-bottom: 15px !important;
                }
    
    
    
                .custom-class-3 {
                    width: 100% !important;
                    height: auto !important;
                    font-size: 10px !important;
                    text-align: justify !important;
                    float: left !important;
                    margin: 0px !important;
                    padding: 3px !important;
                }
    
                .custom-class-4 {
                    width: 100px !important;
                    height: 100% !important;
                    font-size: 12px !important;
                    text-align: right !important;
                    float: right !important;
                    margin: 0px auto 0px auto !important;
                    padding: 10px 10px 10px 10px !important;
                }
    
                .custom-logo-class {
                    padding: 10px 10px 10px 10px !important;
                }
    
                .footer-logo {
                    float: left !important;
                }
    
                .ah-footer-logo {
                    width: 68% !important;
                }
    
                .custom-kenbrown-logo {
                    margin: 20px 0px 0px 0px !important;
                }
    
                .address-mobile {
                    font-size: 13px !important;
                }
    
                .address-mobile-2 {
                    font-size: 15px !important;
                }
    
                .service-button {
                    width: 100% !important;
                }
    
                .custom-class-5 {
                    margin: 15px 0px 0px 0px !important;
                }
    
                .ah-menu {
                    width: 95% !important;
                    margin: 0px 0px 5px 0px !important;
                }
    
                .value-my-part-exchange {
                    width: 100% !important;
                }
    
                .dealership-logo {
                    width: 80% !important;
                    text-align: center !important;
                    margin: 0px 10% 0px 10% !important;
                }
    
                .cta {
                    width: 260px !important;
                }
            }
    
            /* mobile */
            @media only screen and (max-width:480px) {
    
                th[class="banner-centered"] .center-image {
                    width: 90% !important;
                }
    
                .mob_address {
                    display: block;
                }
    
            }
        </style>
    </head>
    
    <body bgcolor="ffffff">
    
        <!-- hidden preview content to show in email clients -->
        <div style="font-size: 1px; color:#fff; display:none;"> </div>
        <!-- end hidden content to show in email clients -->
    
    
        <!-- main wrapper -->
    
        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#efefef">
    
            <tr>
                <th>
                    <!-- email container -->
    
                    <table class="container" width="640" align="center" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                            <th valign="top" class="banner" bgcolor="#ffffff"
                                style="padding:0px 0px 0px 0px; text-align:center; font-family:Arial,sans-serif;">
    
                                <img src="https://emails.bigmarketing.co.uk/2023/priority/mini.jpg"
                                    alt="BUSINESS AS (UN)USUAL" width="100%" height="auto" border="0" style="outline:0;" />
    
                            </th>
                        </tr>
    
                        <tr>
                            <th valign="top" class="banner" bgcolor="#ffffff"
                                style="padding:30px 24px 30px 24px; text-align:left; font-weight:normal; font-family:Arial,sans-serif; line-height:24px; font-size:16px; ">
                                <span style="font-size:22px; line-height:22px; font-weight:bold;">Dear ${title} ${firstName} ${lastName}</span><br /><br />
    
                                Congratulations on registering for the MINI Priority Loyalty Programme. We are delighted to confirm that MINI Financial Services have notified us that you have been successful in securing an exclusive Loyalty Offer from them. 
                                We will contact you in accordance with your contact preferences about your offer based on the information you provided<br /><br />
    
                                <strong>The vehicle(s) you are interested in:</strong><br />
                                ${preferencesArray.length > 1 ? "Vehicle types:" : "Vehicle type:"} ${preferencesArray.join(", ")}<br />
                                ${modelsArray.length > 1 ? "Models of interest:" : "Model of interest:"} ${modelsArray.join(", ")}<br /><br />

                                <strong>Details of how you asked to be contacted back:</strong><br />
                                ${appointmentMethodsArray.length > 1 ? "Appointment Types:" : "Appointment Type:"} ${appointmentMethodsArray.join(", ")}<br />
                                ${contactMethodsArray.length > 1 ? "Contact methods:" : "Contact method:"} ${contactMethodsArray.join(", ")}<br />
                                ${contactTimesArray.length > 1 ? "Contact times:" : "Contact time:"} ${contactTimesArray.join(", ")}<br />
                                ${contactDaysArray.length > 1 ? "Contact days:" : "Contact day:"} ${contactDaysArray.join(", ")}<br /><br />

                                <strong>Details of your preferred finance budget:</strong><br />
                                Monthly budget: ${budgetsArray}<br />
                                Initial deposit: £${deposit}<br />
                                Average annual mileage: ${mileagesArray}<br /><br />
    
                                <strong>Details of your current vehicle and finance agreement:</strong><br />
                                Ownership: ${ownershipsArray}<br />
                                Make: ${make}<br />
                                Model: ${model}<br />
                                Registration: ${registration}<br />
                                Current mileage: ${currentMileage}<br />
                                Transmission: ${transmission}<br />
                                Fuel: ${fuel}<br />
                                Colour: ${colour}<br />
                                Condition: ${conditionsArray}<br />                                
                                Current monthly payment: £${monthlyPayment} <br />
                                Finance contract start date: ${contractStartDate} <br />
                                Finance contract renewal date: ${contractEndDate} <br />
                                Comments: ${comments.length > 0 ? comments : "None specified"}<br /><br />
    
                                <strong>Your contact details:</strong><br />
                                Mobile number:  ${mobile}<br /> 
                                Other phone number: ${phone}<br /> 
                                Email address:  ${emailAddress}<br /><br />

                                If any of the information listed here is incorrect, please give us a call on ${branchTelephone} and we can help.

                            </th>
                        </tr>
    
                        <tr>
                            <th valign="top" class="banner" bgcolor="#ffffff"
                                style="padding:0px 0px 0px 0px; font-family:Arial,sans-serif;">
    
                                <img src="https://emails.bigmarketing.co.uk/2023/priority/bmw-mini-separator.jpg"
                                    alt="Book Here" height="auto" width="100%%" border="0" style="outline:0;" />
    
                            </th>
                        </tr>
    
                        <tr>
                            <th valign="top" class="banner" bgcolor="#ffffff"
                                style="padding:15px 24px 15px 24px; font-weight:normal; font-family:Arial,sans-serif; line-height:24px; font-size:16px; ">
                                <address>
                                    <strong> ${dealerName} ${branchTradingName}</strong><br>
                                    ${branchAddress1}, ${branchAddress2},<br>
                                    ${branchAddress3}, ${branchPostcode}<br>
                                    <strong>Tel. ${branchTelephone}</strong><br>
                                </address>
                            </th>
                        </tr>

                          <tr>
                            <th valign="top" class="banner" bgcolor="#ffffff"
                                style="padding:20px 24px 50px 24px; font-weight:normal; color:#000000;	">
                                <div class="content-type-select">
                                    <a href="https://www.facebook.com/sycamoremini" target="_blank"><img
                                            class="social-icon" alt="Facebook"
                                            src="https://emails.bigmarketing.co.uk/2020/peter-cooper/w45-v2/images/fb.png"
                                            style="outline:0; width:40px !important; padding-left:0px; padding-right:15px; margin-top:0px;"
                                            width="40" border="0"></a><a
                                        href="https://twitter.com/sycamoremini"
                                        target="_blank"><img class="social-icon" alt="YouTube"
                                            src="https://emails.bigmarketing.co.uk/2020/peter-cooper/w45-v2/images/tw.png"
                                            style="outline:0; width:40px !important; padding-left:0px; padding-right:15px; margin-top:0px;"
                                            width="40" border="0"></a><a
                                        href="https://www.instagram.com/sycamore_mini"
                                        target="_blank"><img class="social-icon" alt="Instagram"
                                            src="https://emails.bigmarketing.co.uk/2020/peter-cooper/w45-v2/images/insta.png"
                                            style="outline:0; width:40px !important; padding-left:0px; padding-right:15px; margin-top:0px;"
                                            width="40" border="0"></a>
                                </div>
                            </th>
                        </tr>
    
    
    
                        <tr>
                            <th valign="top" class="banner" bgcolor="#ffffff"
                                style="padding:0px 24px 50px 24px; font-weight:normal; font-family:Arial,sans-serif; line-height:18px; font-size:13px; ">
                                <p>    
                                    Sycamore Peterborough MINI<br><br>
Registered in England No. 216564. Registered office: C/O First Hamblin (Eastern) Limited Papyrus
Business Parc Werrington Peterborough PE4 5BH. Sycamore (Peterborough) Limited is authorised
and regulated by the Financial Conduct Authority for its regulated consumer credit activities.<br><br>
For further information on how we arrange finance and insurance see
<a href="https://www.sycamoremini.co.uk/finance-and-offers/options">https://www.sycamoremini.co.uk/finance-and-offers/options</a> <br><br>
To understand how we use and process your data please see our Privacy Notice see
<a href="www.sycamoremini.co.uk/footer/privacy-policy">www.sycamoremini.co.uk/footer/privacy-policy</a> <br><br>
You are receiving this email because you opted in to receive information from Sycamore Peterborough
MINI either via our website, in one of our showrooms or when you purchased your vehicle/visited our
workshop.
Sycamore Peterborough MINI, Registered in England No. 216564. Registered office: C/O First Hamblin
(Eastern) Limited Papyrus Business Parc Werrington Peterborough PE4 5BH. Sycamore Peterborough
MINI takes your privacy seriously. We will only use your personal information to provide the products
and services you have requested from us. We may need to share your details with third parties in
order to provide our services to you. <br><br>
Our full Privacy Notice is published on our website Privacy Notice | Sycamore Peterborough MINI and
is also available on request.
If the link above does not work then apologies, the offers have now been withdrawn.
                                </p>
                            </th>
                        </tr>
    
                    </table>
    
                    <!-- end email container -->
                </th>
            </tr>
        </table>
        <!-- end main wrapper -->
    
    </body>
    
    </html>`;

    const dealerTemplate = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
    
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    
        <title> Motor Group</title>
    
        <style type="text/css" rel="stylesheet">
            body {
                margin: 0px;
                padding: 0px;
                color: #000
            }
    
            th {
                text-align: left;
            }
    
            th img {
                height: auto !important;
            }
    
            .col-2-promos p,
            .col-3-promos p {
                margin: 0px;
            }
    
            .banner p {
                margin: 0px;
            }
    
            /*Medium screens*/
    
            @media only screen and (max-width:660px) {
                table[class=container] {
                    width: 90% !important;
                }
    
                th[class=banner] img {
                    width: 100%;
                    height: auto !important;
                }
    
                th[class="col-2-promos"] {
                    width: 48% !important;
                    margin-bottom: 0px;
                    display: inline-block;
                    text-align: center !important;
                }
    
                th[class="col-2-promos"] img {
                    width: 99%;
                    height: auto !important;
                }
    
                th[class="col-2-promos"] img[class="footerImg"] {
                    width: auto !important;
                    height: auto !important;
                }
    
                th[class="col-3-promos"] {
                    width: 30% !important;
                    margin-bottom: 20px;
                    text-align: center !important;
                }
    
                th[class="col-3-promos"] img {
                    width: 99% !important;
                    height: auto !important;
                }
    
                th[class="col-4-promos"] {
                    width: 24% !important;
                    margin-bottom: 10px;
                    text-align: center !important;
                    padding: 0px
                }
    
                th[class="col-4-promos"] img {
                    width: 95% !important;
                }
    
                table[class="buttonTable"] {
                    width: 100% !important;
                    margin-bottom: 20px;
                    text-align: center !important;
                }
    
                table[class="customTable"] {
                    width: 100% !important;
                    margin-bottom: 20px;
                    text-align: center !important;
                }
    
                .mobile-logo {
                    margin-left: 32%;
                    margin-right: 32%;
                    width: 36% !important;
                    margin-top: 20px;
                }
    
                .custom-class-1 {
                    width: 85% !important;
                }
    
                .custom-class-2 {
                    width: 100% !important;
                    font-size: 11px !important;
                    text-align: left !important;
                    float: left !important;
                }
    
                .offer {
                    width: 100% !important;
                    padding: 0 0 20px 0 !important;
                }
    
                .finance-table {
                    width: 100% !important;
                    font-size: 12px !important;
                }
    
                .custom-logo-class-2 {
                    width: 30px !important;
                }
    
                .custom-logo {
                    float: left !important;
                    margin: 15px 0 0 8px !important;
                }
    
                .custom-offer {
                    width: 100% !important;
                }
    
                .kenbrownfooter {
                    width: 100% !important;
                }
    
                .social-icons-98 {
                    width: 30px !important;
                    height: 30px !important;
                    text-align: center !important;
                }
    
                .value-my-car {
                    width: 100% !important;
                }
    
                .finance-table-1 {
                    width: 100% !important;
                }
            }
    
    
            /* small screens */
            @media only screen and (max-width:620px) {
    
                table[class=container] {
                    width: 100% !important;
    
                }
    
                /* outermost container*/
    
                th[class="col-row"] {
                    width: 100% !important;
                }
    
                table[class=container] th {
                    border: none !important;
                }
    
                th[class="col-2-promos"] {
                    width: 100% !important;
                    margin-bottom: 0px;
                    display: inline-block;
                    text-align: center !important;
                }
    
                th[class="col-2-promos"] img {
                    width: 99%;
                    height: auto !important;
                }
    
                th[class="col-3-promos"] {
                    width: 100% !important;
                    margin-bottom: 20px;
                    display: inline-block;
                    text-align: center !important;
                }
    
                th[class="col-4-promos"] {
                    width: 49% !important;
                    margin-bottom: 10px;
                    display: inline-block;
                    text-align: center !important;
                }
    
                th[class="col-4-promos"] img {
                    width: 95% !important;
                    margin: 0 auto !important;
                    height: auto !important;
                }
    
                table[class="buttonTable"] {
                    width: 100% !important;
                    margin-bottom: 20px;
                    text-align: center !important;
                }
    
                th[class="button"] {
                    width: 100% !important;
                    padding: 0 20px 0 20px;
                }
    
                .footer-logo-template-1-img {
                    float: none !important;
                }
    
                .social-icon {
                    width: 25px !important;
                    padding-left: 2px !important;
                    padding-right: 2px !important;
                    margin-top: 15px !important;
                    margin-bottom: 15px !important;
                }
    
    
    
                .custom-class-3 {
                    width: 100% !important;
                    height: auto !important;
                    font-size: 10px !important;
                    text-align: justify !important;
                    float: left !important;
                    margin: 0px !important;
                    padding: 3px !important;
                }
    
                .custom-class-4 {
                    width: 100px !important;
                    height: 100% !important;
                    font-size: 12px !important;
                    text-align: right !important;
                    float: right !important;
                    margin: 0px auto 0px auto !important;
                    padding: 10px 10px 10px 10px !important;
                }
    
                .custom-logo-class {
                    padding: 10px 10px 10px 10px !important;
                }
    
                .footer-logo {
                    float: left !important;
                }
    
                .ah-footer-logo {
                    width: 68% !important;
                }
    
                .custom-kenbrown-logo {
                    margin: 20px 0px 0px 0px !important;
                }
    
                .address-mobile {
                    font-size: 13px !important;
                }
    
                .address-mobile-2 {
                    font-size: 15px !important;
                }
    
                .service-button {
                    width: 100% !important;
                }
    
                .custom-class-5 {
                    margin: 15px 0px 0px 0px !important;
                }
    
                .ah-menu {
                    width: 95% !important;
                    margin: 0px 0px 5px 0px !important;
                }
    
                .value-my-part-exchange {
                    width: 100% !important;
                }
    
                .dealership-logo {
                    width: 80% !important;
                    text-align: center !important;
                    margin: 0px 10% 0px 10% !important;
                }
    
                .cta {
                    width: 260px !important;
                }
            }
    
            /* mobile */
            @media only screen and (max-width:480px) {
    
                th[class="banner-centered"] .center-image {
                    width: 90% !important;
                }
    
                .mob_address {
                    display: block;
                }
    
            }
        </style>
    </head>
    
    <body bgcolor="ffffff">
    
        <!-- hidden preview content to show in email clients -->
        <div style="font-size: 1px; color:#fff; display:none;"> </div>
        <!-- end hidden content to show in email clients -->
    
    
        <!-- main wrapper -->
    
        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#efefef">
    
            <tr>
                <th>
                    <!-- email container -->
    
                    <table class="container" width="640" align="center" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                            <th valign="top" class="banner" bgcolor="#ffffff"
                                style="padding:0px 0px 0px 0px; text-align:center; font-family:Arial,sans-serif;">
    
                                <img src="https://emails.bigmarketing.co.uk/2023/priority/mini.jpg"
                                    alt="BUSINESS AS (UN)USUAL" width="100%" height="auto" border="0" style="outline:0;" />
    
                            </th>
                        </tr>
    
    
                        <tr>
                        <th valign="top" class="banner" bgcolor="#ffffff"
                            style="padding:30px 24px 30px 24px; text-align:left; font-weight:normal; font-family:Arial,sans-serif; line-height:24px; font-size:16px; ">
                            <span style="font-size:22px; line-height:22px; font-weight:bold;">Dear Dealer</span><br /><br />

                            ${title} ${firstName} ${lastName} has registered their interest in the MINI Priority Loyalty Programme. Please ensure you follow the customer’s contact preferences below. <br /><br />

                            <strong>These are the vehicle(s) they are interested in:</strong><br />
                            ${preferencesArray.length > 1 ? "Vehicle types:" : "Vehicle type:"} ${preferencesArray.join(", ")}<br />
                            ${modelsArray.length > 1 ? "Models of interest:" : "Model of interest:"} ${modelsArray.join(", ")}<br /><br />

                            <strong>These are their contact preferences:</strong><br />
                            ${appointmentMethodsArray.length > 1 ? "Appointment Types:" : "Appointment Type:"} ${appointmentMethodsArray.join(", ")}<br />
                            ${contactMethodsArray.length > 1 ? "Contact methods:" : "Contact method:"} ${contactMethodsArray.join(", ")}<br />
                            ${contactTimesArray.length > 1 ? "Contact times:" : "Contact time:"} ${contactTimesArray.join(", ")}<br />
                            ${contactDaysArray.length > 1 ? "Contact days:" : "Contact day:"} ${contactDaysArray.join(", ")}<br />
                            

                            <strong>These are the details of their preferred finance budget:</strong><br />
                            Monthly budget: ${budgetsArray}<br />
                            Initial deposit: £${deposit}<br />
                            Average annual mileage: ${mileagesArray}<br /><br />

                            <strong>These are the details relating to their current vehicle and finance agreement:</strong><br />
                            Ownership: ${ownershipsArray}<br />
                            Make: ${make}<br />
                            Model: ${model}<br />
                            Registration: ${registration}<br />
                            Current mileage: ${currentMileage}<br />
                            Transmission: ${transmission}<br />
                            Fuel: ${fuel}<br />
                            Colour: ${colour}<br />
                            Condition: ${conditionsArray}<br />                            
                            Current monthly payment: £${monthlyPayment} <br />
                            Finance contract start date: ${contractStartDate} <br />
                            Finance contract renewal date: ${contractEndDate} <br />
                            Comments: ${comments.length > 0 ? comments : "None specified"}<br /><br />

                            <strong>These are their contact details:</strong><br />
                            Mobile number:  ${mobile}<br /> 
                            Other phone number: ${phone}<br /> 
                            Email address:  ${emailAddress}<br /><br />
                        </th>
                    </tr>
    
                        <tr>
                            <th valign="top" class="banner" bgcolor="#ffffff"
                                style="padding:0px 0px 0px 0px; font-family:Arial,sans-serif;">
    
                                <img src="https://emails.bigmarketing.co.uk/2023/priority/bmw-mini-separator.jpg"
                                    alt="Book Here" height="auto" width="100%%" border="0" style="outline:0;" />
    
                            </th>
                        </tr>
    
                        <tr>
                            <th valign="top" class="banner" bgcolor="#ffffff"
                                style="padding:15px 24px 15px 24px; font-weight:normal; font-family:Arial,sans-serif; line-height:24px; font-size:16px; ">
                                <address>
                                    <strong> ${dealerName} ${branchTradingName}</strong><br>
                                    ${branchAddress1}, ${branchAddress2},<br>
                                    ${branchAddress3}, ${branchPostcode}<br>
                                    <strong>Tel. ${branchTelephone}</strong><br>
                                </address>
                            </th>
                        </tr>
    
                         <tr>
                            <th valign="top" class="banner" bgcolor="#ffffff"
                                style="padding:20px 24px 50px 24px; font-weight:normal; color:#000000;	">
                                <div class="content-type-select">
                                    <a href="https://www.facebook.com/sycamoremini" target="_blank"><img
                                            class="social-icon" alt="Facebook"
                                            src="https://emails.bigmarketing.co.uk/2020/peter-cooper/w45-v2/images/fb.png"
                                            style="outline:0; width:40px !important; padding-left:0px; padding-right:15px; margin-top:0px;"
                                            width="40" border="0"></a><a
                                        href="https://twitter.com/sycamoremini"
                                        target="_blank"><img class="social-icon" alt="YouTube"
                                            src="https://emails.bigmarketing.co.uk/2020/peter-cooper/w45-v2/images/tw.png"
                                            style="outline:0; width:40px !important; padding-left:0px; padding-right:15px; margin-top:0px;"
                                            width="40" border="0"></a><a
                                        href="https://www.instagram.com/sycamore_mini"
                                        target="_blank"><img class="social-icon" alt="Instagram"
                                            src="https://emails.bigmarketing.co.uk/2020/peter-cooper/w45-v2/images/insta.png"
                                            style="outline:0; width:40px !important; padding-left:0px; padding-right:15px; margin-top:0px;"
                                            width="40" border="0"></a>
                                </div>
                            </th>
                        </tr>
    
    
    
                        <tr>
                            <th valign="top" class="banner" bgcolor="#ffffff"
                                style="padding:0px 24px 50px 24px; font-weight:normal; font-family:Arial,sans-serif; line-height:18px; font-size:13px; ">
                                <p>    
                                    Sycamore Peterborough MINI<br><br>
Registered in England No. 216564. Registered office: C/O First Hamblin (Eastern) Limited Papyrus
Business Parc Werrington Peterborough PE4 5BH. Sycamore (Peterborough) Limited is authorised
and regulated by the Financial Conduct Authority for its regulated consumer credit activities.<br><br>
For further information on how we arrange finance and insurance see
<a href="https://www.sycamoremini.co.uk/finance-and-offers/options" target="_blank">https://www.sycamoremini.co.uk/finance-and-offers/options</a> <br><br>
To understand how we use and process your data please see our Privacy Notice see
<a href="https://www.sycamoremini.co.uk/footer/privacy-policy" target="_blank">www.sycamoremini.co.uk/footer/privacy-policy</a> <br><br>
You are receiving this email because you opted in to receive information from Sycamore Peterborough
MINI either via our website, in one of our showrooms or when you purchased your vehicle/visited our
workshop. Sycamore Peterborough MINI, Registered in England No. 216564. Registered office: C/O First Hamblin
(Eastern) Limited Papyrus Business Parc Werrington Peterborough PE4 5BH. Sycamore Peterborough
MINI takes your privacy seriously. We will only use your personal information to provide the products
and services you have requested from us. We may need to share your details with third parties in
order to provide our services to you. <br><br>
Our full Privacy Notice is published on our website Privacy Notice | Sycamore Peterborough MINI and
is also available on request.
If the link above does not work then apologies, the offers have now been withdrawn.
                                </p>
                            </th>
                        </tr>
    
                    </table>
    
                    <!-- end email container -->
                </th>
            </tr>
        </table>
        <!-- end main wrapper -->
    
    </body>
    
    </html>`;
    //returns an object giving access to both templates and can be accessed as const [getTemplate] = useTemplate() then getTemplate().customerTemplate or getTemplate().dealerTemplate
    return { customerTemplate, dealerTemplate };
  };
  // UseTemplate is a funtion which returns the function getTemplate in an array to be accessed using useState() syle hook as const [getTemplate] = useTemplate();
  return [getTemplate];
};

export default useTemplate;
