import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import getSize from "viewport-size";

import { Context } from "../context/context";
import EventImage from "../assets/images/event-image.png";

const Welcome = () => {
  let history = useHistory();
  const {
    state: {
      data: { details },
    },
    dispatch,
  } = useContext(Context);

  const [detailsFields, setDetailsFields] = useState(JSON.parse(localStorage.getItem("details")) || details);

  const navigateNext = (slug) => {
    // First set the layout to completed and then
    dispatch({ type: "SET_LAYOUT_VISITED", slug: "priority" });
    //history.location.pathname.replace(/\\|\//g, "").toLowerCase()
    history.push(slug);
  };

  // This useEffect will run on first load to check what is in localstorage and populate our context with it
  useEffect(() => {
    const details = localStorage.getItem("details");

    if (details) {
      dispatch({ type: "UPDATE_ALL_DETAILS", data: JSON.parse(details) });
      setDetailsFields(details);
    }

    // Scroll to top on mobile screens only page load
    if (getSize.getWidth() < 575) {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 420,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    } else {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect will set the data into localstorage on selection change
  useEffect(() => {
    setDetailsFields(details);
    localStorage.setItem("details", JSON.stringify(details));
  }, [details]);
  return (
    <div className="card border-0 mb-5 my-md-0 mb-0 mb-md-5">
      <div className="card-body">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-8 order-2 order-md-1 text-center text-md-left my-4 my-md-0">
            <h1 className="page-title mb-0">Welcome</h1>
          </div>
          <div className="col-8 col-md-4 order-1 order-md-2">

          </div>
        </div>

        <div className="row text-center text-md-left mt-3">
          <div className="col-12">
            <h3 className="page-sub-title">
              Dear {detailsFields.firstName} {detailsFields.lastName},
            </h3>
          </div>
        </div>
        <div className="row text-center text-md-left justify-content-between mt-5">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <p>
                  Our MINI Priority Loyalty Programme offers Priority Customers additional financial support towards upgrading your current vehicle to another MINI before  30<sup>th</sup> June 2023.
                </p>
                <p>
                  You’re in a great position to change your vehicle and we would love the opportunity to discuss your upgrade the way you want to.
                </p>
                <p>
                  Since you purchased your last vehicle, new car build times are taking longer, so we are offering you a simpler way to choose your next car. You can browse from our stock or build a bespoke vehicle from the factory in any way you choose.

                </p>
                <p>
                  As a selected Priority Customer, you qualify for additional financial incentives AND you can choose how you would like to interact with us;

                </p>
              </div>
            </div>

            <div className="row d-none d-md-block mt-2">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="row">
                  <div className="col-12">
                    <div className="btn btn-primary" onClick={() => navigateNext("priority")}>
                      Next
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-none">
            <p>
              Once all these steps have been taken we will provide you with a summary of all the details, please check and if OK, click ‘Build My Loyalty offer’ and we will send your bespoke MINI Priority Loyalty offer to  MINI, who will contact you at your requested time as a
              matter of urgency.
            </p>

            <h5>Check out the MINI Priority Loyalty Programme benefits:</h5>

            <ul className="pl-3">
              <li className="text-primary">Select any car from the MINI range</li> <li className="text-primary">Adjust your finance budget</li> <li className="text-primary">Exclusive access to MINI Priority Loyalty offers</li>{" "}
              <li className="text-primary"> Additional deposit contributions</li>{" "}
              <li className="text-primary">
                Extra vehicle discount support* <br />
                <span className="small">( * Extra discount support could be connected to Deposit Contribution, only available on stock new cars sold and delivered by 30th July 2021 )</span>
              </li>{" "}
              <li className="text-primary"> Hundred’s of stock vehicles available, ready for delivery now!</li>
            </ul>
          </div>
        </div>

        <div className="row text-center text-md-left mt-5 d-md-none">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="row">
              <div className="col-12">
                <div className="btn btn-primary" onClick={() => navigateNext("priority")}>
                  Next
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
