import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import getSize from "viewport-size";
import Spinner from "react-bootstrap/Spinner";
import { Context } from "../context/context";
import useFetch from "../hooks/useFetch";

import EventImage from "../assets/images/event-image-alt.png";

import axios from 'axios';

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});
let value = params.vsms; // "some_value"

console.log(value);
if(value){
    setTimeout(function () {
        document.getElementById("eventCode").value = value;
    }, 100);
}

console.log(params);
if(params.code){
    setTimeout(function () {
        document.getElementById("eventCode").value = params.code;
    }, 100);
}

if(params.vsms != null) {


    axios.get(`https://api.big-events.co.uk/microsite_login/${value}`)
        .then(res => {
            const persons = res.data;
            console.log(persons.url);
            //window.location('https://westendkiavipevent.co.uk?vsms=WEK63BFBC1B966802');
            window.location.href = persons.url;
        })
}

const Login = () => {
  const initialState = {
    eventCode: "",
  };

  const location = useLocation();

  const [loginFields, setLoginFields] = useState(initialState);
  const { dispatch } = useContext(Context);

  const handleInputChange = (event) => {
    event.preventDefault();
    setLoginFields({
      ...loginFields,
      [event.target.name]: event.target.value,
    });
  };

  let [isLoading, error, setError, doFetch] = useFetch(`https://api.big-events.co.uk/microsite_login/${loginFields.eventCode}`);
  // let [isLoading, error, setError, doFetch] = useFetch(`https://admin-demo.big-events.co.uk/microsite_login/${loginFields.eventCode}`);
  // let [isLoading, error, setError, doFetch] = useFetch(`https://demo-api.big-events.co.uk/microsite_login/${loginFields.eventCode}`);

  useEffect(() => {
    setLoginFields({
      ...loginFields,
      eventCode: location.hash.replace("#", ""),
    });

    // Scroll to top on mobile screens only page load
    if (getSize.getWidth() < 575) {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 420,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    } else {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async function () {

      const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
      });


      if(params.code != null){
          axios.get(`https://api.big-events.co.uk/microsite_login/${params.code}`)
              .then(res => {
                  const response = res.data;
                  console.log(response);
                  if (response) {
                      dispatch({
                          type: "LOGIN",
                          payload: { response },
                      });
                      dispatch({
                          type: "UPDATE_FROM_API",
                          payload: { response },
                      });
                  }
              })
      } else {
          if (loginFields.eventCode.length > 0) {
              const response = await doFetch({
                  method: "GET",
              });
              if (response) {
                  dispatch({
                      type: "LOGIN",
                      payload: { response },
                  });
                  dispatch({
                      type: "UPDATE_FROM_API",
                      payload: { response },
                  });
              }
          } else {
              setError("Code cannot be empty");
          }
      }



  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 1800);
    }
  }, [error, setError]);
  return (
    <>
      <div className="card border-0 bg-transparent mb-5 my-md-0 mb-0 mb-md-5">
        <div className="card-body">
          <div className="row align-items-start">
            <div className="col-12 order-2 order-md-1 text-center text-md-left my-4 my-md-0">
              <div className="row justify-content-center justify-content-md-start">
                  <div className="col-lg-7 col-md-7 col-xs-12">

                  </div>
                <div className="col-lg-5 col-md-5 col-xs-12">
                  <form
                    onSubmit={(event) => {
                      handleInputChange(event);
                    }}
                  >
                    <div className="form-group">
                      <label className="text-black" htmlFor="email">
                          Enrolment code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="eventCode"
                        placeholder="Enter code"
                        name="eventCode"
                        defaultValue={loginFields.eventCode}
                        onChange={(event) => {
                          handleInputChange(event);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-outline-light"
                        type="submit"
                        onClick={() => {
                          handleLogin();
                        }}
                      >
                        Login
                        {isLoading && <Spinner animation="border" size="sm" className="ml-4" />}
                      </button>
                    </div>
                    {error && <div className="alert alert-danger">{error}</div>}
                  </form>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 order-1 order-md-2">

            </div>
          </div>

          <div className="row text-center text-md-left justify-content-between">
            <div className="col-12 col-md-6"></div>
          </div>

          <div className="row text-center text-md-left mt-5 ">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="row">
                <div className="col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
